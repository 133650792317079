$color-red: #8a1a1c;
$color-blue: #004899;
$color-blue-dark: #003c80;
$color-white: #ffffff;
$color-navy: #1a2e45;
$color-orange: #ffb500;
$color-orange-dark: #dc9d03;
$color-black: black;
$color-offwhite: #f1f3f2;
$color-grey: #cccccc;

$color-text: #1a1a1a;

$overlay-bg-light: linear-gradient(
  90deg,
  #ffffff 0%,
  rgba(255, 255, 255, 0) 78.96%
);

$overlay-bg-dark: rgba(23, 37, 53, 0.59);

$color-button: $color-orange;
$color-button-hover: $color-orange-dark;

$color-basic-button: $color-blue;
$color-basic-button-hover: $color-blue-dark;

$color-light-button: $color-white;
$color-light-button-hover: #e5e5e5;

$color-slide-bg: $color-offwhite;
$color-black-transparent: rgba($color-black, 0.7);
