html {
  box-sizing: border-box;
  overflow: hidden;
}

html,
body,
#root {
  width: 100%;
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  -webkit-font-smoothing: antialiased;
  box-sizing: inherit;
}

// VERY IMPORTANT for modal spacing
.ReactModal__Body--open {
  .ReactModalPortal {
    z-index: 9;

    // position: absolute;
    // top: 0;
    height: 100%;
    width: 100vw;
  }
}

.button {
  -webkit-appearance: none;
}

::selection {
  opacity: 1;
  color: $color-white;
  background: $color-red; /* WebKit/Blink Browsers */
}
::-moz-selection {
  opacity: 1;
  color: $color-white;
  background: $color-red; /* Gecko Browsers */
}

.App {
  height: 100%;
}

.debug {
  background: yellow;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99999;
}

.user-is-tabbing {
  button:focus {
    outline: solid !important;
  }
  span:focus {
    outline: solid !important;
  }
  div:focus {
    outline: solid !important;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
