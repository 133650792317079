.timelineSection {
  width: 100%;
  flex-wrap: wrap;
  color: white;
  display: flex;
  @include breakpoint('medium-') {
    border-top: 2px solid #e6e6e6;
    padding-top: 15px;
  }
  .modal__inner {
    padding-bottom: 0 !important;
  }
}

.timelineSection__image {
  position: relative;
  @include width(6, 'xlarge');
  position: relative;
  @include breakpoint('large') {
    @include width(6, 'large');
  }
  @include breakpoint('medium-') {
    margin: 0;
    margin-top: 32px;
    width: 100%;
  }

  .imageWrap {
    position: sticky;
    top: 80px;
  }

  .button {
    position: absolute;
    z-index: 4;
    bottom: 12px;
    right: 12px;
    z-index: 3;
    display: inline-flex;
    @include breakpoint('medium-') {
      position: relative;
      right: auto;
      bottom: auto;
      margin-bottom: 32px;
      color: $color-blue;
      background: $color-white;
      path {
        fill: $color-blue;
      }
    }
  }

  img {
    width: 100%;
    display: block;
  }
}

.timelineSection__timeline {
  @include width(6, 'xlarge');
  padding-bottom: 30px;
  margin-left: map-get($inner-gutters, 'xlarge');

  @include breakpoint('large') {
    @include width(6, 'large');
    margin-left: map-get($inner-gutters, 'large');
  }

  @include breakpoint('medium-') {
    margin: 0;
    width: 100%;
  }
}

.timelineSection__header {
  display: flex;
  align-items: center;

  .timelineSection__title {
    @include header-03;
  }
}

.timelineSection__intro--mobile {
  display: none;
  @include breakpoint('medium-') {
    display: block;
  }
}

.timelineSection__intro--desktop {
  @include breakpoint('medium-') {
    display: none;
  }
}

.timelineSection__avatar {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  margin-right: 24px;
  overflow: hidden;
  img {
    height: 50px;
    width: 50px;
    display: block;
  }
}

.timelineSection__info {
  margin-top: 32px;
  @include width(5, 'xlarge');
  @include breakpoint('large') {
    @include width(5, 'large');
  }

  @include breakpoint('medium-') {
    width: 100%;
  }

  p {
    @include ui-08;
  }

  .timelineSection__filter-container {
    margin-top: 2rem;

    .timelineSection__filter-checkbox {
      // 
    }

    .timelineSection__filter-text {
      @include ui-08;
      margin-left: 0.5rem;
    }
  }
}
