.infoPopup {
  @include ui-02;
  height: auto;
  color: $color-white;
  width: 320px;
  padding: 16px 18px;
  background: $color-navy;

  p + .button {
    margin-top: 0;
    margin-top: 20px;
  }

  .button {
    height: auto;
    margin-top: 0;
    background: $color-navy;
    padding: 0;
    padding-left: 0;
    color: $color-white;
    transition: 0.4s all;

    &:hover,
    &:focus {
      background: $color-navy;
      transform: translateX(10px);
    }

    svg {
      position: relative;
      top: -1px;
      margin-left: 18px;
    }

    path {
      fill: $color-orange;
    }
  }
}

.infoPopup--glossary {
  .button {
    margin-top: 20px;
    border-radius: 2px;
    padding: 16px;
    color: $color-blue;
    background: $color-white;

    display: inline-flex;

    &:hover,
    &:focus {
      color: $color-blue;
      background: $color-light-button-hover;
      transform: translateX(0px);
    }

    svg {
      position: relative;
      margin-left: 18px;
    }

    path {
      fill: $color-blue;
    }
  }
}
