.learnMore {
  @extend %container;
  padding-top: 32px;
  display: flex;
  @include breakpoint('medium-') {
    padding-top: 48px;
  }
}

$card: (
  xsmall: 12,
  small: 12,
  medium: 12,
  large: 4,
  xlarge: 4,
);

.card {
  margin-top: 16px;
  padding-bottom: 28px;
  @include column-flex($card);
  @include breakpoint('medium-') {
    margin-top: 40px;
    padding-bottom: 0;
    &:first-child {
      margin-top: 0;
    }
  }
  h3 {
    font-size: 28px;
    line-height: 115%;
    letter-spacing: -0.01em;
  }

  .card__content {
    margin-top: 12px;
  }

  p {
    @include ui-09;
  }

  a {
    margin-top: 20px;
  }
}

.cardWrapper {
  @include columns-container-flex;
  @include breakpoint('medium-') {
    padding-top: 12px;
  }
}

.learnMore__content {
  width: 100%;
  border-top: 2px solid black;
}
