.wordDefine {
  color: $color-blue;
  cursor: pointer;
  text-decoration: none;
  background-image: linear-gradient(
    to bottom,
    $color-blue 75%,
    $color-blue 75%
  );
  background-repeat: repeat-x;
  background-size: 1px 1px;
  background-position: 0 98%;
  transition: 0.4s all;
  white-space: nowrap;

  &:hover,
  &:focus {
    // @include hover-supported() {
    color: $color-red;
    background-image: linear-gradient(
      to bottom,
      $color-red 75%,
      $color-red 75%
    );
    // }
  }
}

.overflowScreen {
  display: none;
  @include breakpoint('medium-') {
    display: block;
    box-shadow: -2px -24px 21px 10px #f5f2ed;
    width: 100%;
    height: 40px;
    bottom: -40px;
    z-index: 4;
    position: absolute;
  }
}
