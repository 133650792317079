.cta {
  @include link;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s;
  svg {
    transition: all 0.3s;
    margin-left: 8px;
  }
  &:hover {
    @include hover-supported {
      color: $color-blue-dark;
      path {
        fill: $color-blue-dark;
      }
    }
  }
}
