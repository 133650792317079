.sliderHeader {
  top: 0;
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 80px;
  background: linear-gradient(180deg, #f1f3f2 53%, rgba(255, 255, 255, 0) 100%);
  @include breakpoint('medium-') {
    position: fixed;
    z-index: 3;

    left: 0;
    background: #f5f2ed;
    border-bottom: 2px solid #cccccc;
    height: calc(60px + 64px);
  }

  .panel {
    height: auto;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 0 30px;
    @include breakpoint('medium-') {
      padding: 0 20px;
    }
  }
}

.sliderHeader__main {
  @extend %container;
  display: flex;
  height: 80px;
  align-items: center;
  @include breakpoint('medium-') {
    height: 60px;
  }
}

.sliderHeader__links {
  margin-left: auto;
  @include breakpoint('large+') {
    button {
      @include buttonReset;
      @include link;
      margin-left: 32px;
      &:hover {
        color: $color-blue-dark;
      }
    }
  }
  @include breakpoint('medium-') {
    display: none;

    &.is-open {
      display: block;
      position: absolute;
      height: 120px;
      width: 100vw;
      left: 0px;
      z-index: 3;
      bottom: -122px;
    }

    a,
    button {
      @include link;
      @include buttonReset;
      justify-content: center;
      align-items: center;
      width: 100%;
      display: flex;
      margin: 0;
      background: #f5f2ed;
      height: 60px;
      border-bottom: 1px solid #cccccc;
    }
  }
}

.sliderHeader__controls {
  display: none;
}

.backLink {
  @include buttonReset;
  @include link;
  color: black;
  display: flex;
  align-items: center;
  padding: 0;
  @include breakpoint('medium-') {
    padding: 0;
    position: relative;
    top: 2px;
  }
  svg {
    top: -2px;
    position: relative;
    margin: 0;
    margin-right: 8px;
    transform: rotate(180deg);
  }
  &:hover {
    color: $color-blue-dark;
    path {
      fill: $color-blue-dark;
    }
  }
  .backLink__extra {
    padding-right: 6px;
    @include breakpoint('medium-') {
      display: none;
    }
  }
}

.title {
  margin-left: 32px;
  display: flex;
  align-items: center;
  @include breakpoint('medium-') {
    margin-left: 0;
    position: relative;
  }

  h1 {
    @include header-04;
    text-transform: none;
    font-weight: 700;
    line-height: 170%;
    color: $color-black;
  }
  @include breakpoint('medium-') {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    .title__avatar {
      display: none;
    }
  }
}

.title__avatar {
  border: 1px solid #cccccc;
  align-self: center;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  margin-right: 15px;
}

.mobileMenu {
  margin-left: auto;
  display: none;
  @include breakpoint('medium-') {
    display: block;
  }
}

.sliderHeader__controls {
  display: none;

  @include breakpoint('medium-') {
    display: flex;
    align-items: center;
    @include link;
    height: 64px;
  }
}

.panel--first {
  @include breakpoint('medium-') {
    border-bottom: 2px solid #cccccc;
  }
}

.mobileMenu {
  @include buttonReset;
  .mobileMenu__close path {
    fill: black;
  }
}

.sliderHeader {
  button.slideArrow--prev {
    width: auto;
    padding: 0;
  }
  button.slideArrow--next {
    margin-left: 15px;
    background: $color-white;
    border: 1px solid $color-blue;
  }
}

.slideHeader__arrows {
  margin-left: auto;
  button {
    transition: 0.3s opacity;
    @include buttonReset;
    padding: 10px 8px;
    border-radius: 2px;
    height: 52px;
    width: 52px;
    position: relative;
    top: -2px;

    svg {
      position: relative;
      top: 1px;
    }
  }
  .slideArrow--next {
    // margin-left: 15px;
    background: $color-white;
    border: 1px solid $color-blue;
  }

  .slideArrow--disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

.sliderHeader__count {
  @include link;
}
