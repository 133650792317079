.main {
  transition: 0.4s opacity;
  opacity: 1;

  &.is-hidden {
    opacity: 0;
  }
}

@mixin slideText {
  @include ui-01;
  p {
    margin-top: 25px;
    &:first-child {
      margin-top: 0;
    }
  }
}

.page--slides {
  .slideSection {
    height: 100vh;
    width: 100%;
  }

  // this is the scroller
  .slide {
    width: 100%;
    height: 100vh; // this is what lets it scroll
    position: relative;
    overflow: auto;
    overflow-x: hidden;
    bottom: 70px;
    padding-top: 70px;
  }

  .slide__content {
    @extend %container;
    padding-top: 100px;
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    align-content: flex-start;
    @include breakpoint('medium-') {
      padding-top: 124px;
      display: block;
      padding-bottom: 80px;
    }
  }

  .slide__image {
    order: 2;
    position: relative;

    @include breakpoint('large+') {
      width: auto;
      margin-left: 0;
    }

    @include breakpoint('large') {
      @include width(7, 'large');
      @include push(1, 'large');
    }

    @include breakpoint('xlarge') {
      @include width(7, 'xlarge');
      @include push(1, 'xlarge');
    }

    img {
      width: 100%;
    }

    width: 100vw;
    margin-left: -16px;

    .mobileImage {
      display: none;
      @include breakpoint('medium-') {
        display: block;
      }
    }

    .desktopImage {
      pointer-events: none;
      -o-user-select: none;
      user-select: none;
      @include breakpoint('medium-') {
        display: none;
      }
    }
  }

  .slide__text {
    @include slideText;
    order: 1;
    padding-top: 40px;
    u {
      text-decoration: none;
      background-image: linear-gradient(to bottom, black 75%, black 75%);
      background-repeat: repeat-x;
      background-size: 1px 1px;
      background-position: 0 98%;
    }

    @include breakpoint('medium-') {
      padding-top: 20px;
    }

    @include breakpoint('large+') {
      @include width(4, 'large');
      margin-right: map-get($inner-gutters, 'large');
    }

    @include breakpoint('xlarge') {
      @include width(4, 'xlarge');
      margin-right: map-get($inner-gutters, 'xlarge');
    }

    .continue-cta-container {
      margin-top: 30px;

      .continue-cta {
        @include buttonReset;
        background: $color-white;
        border: 1px solid $color-blue;
        border-radius: 2px;
        color: $color-blue;
        height: 52px;
        padding: 10px 16px;
        transition: 0.3s opacity;
  
        svg {
          margin-left: 8px;
        }
      }
    }
  }

  .slide__audio {
    .audioPlayer {
      justify-content: end;
      margin-top: 25px;

      .audioPlayer__trigger {
        color: $color-blue;
      }
    }
    &.slide__audio-desktop {
        display: none;
        @include breakpoint('large+') {
          display: initial;
        }
    }
    &.slide__audio-mobile {
        @include breakpoint('large+') {
            display: none;
        }
    }
  }

  .slide__hotspots {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.slide--invert {
  .slide__content {
    .slide__text {
      order: 2;
    }
    .slide__image {
      order: 1;
      @include breakpoint('large+') {
        margin-left: 0;
        margin-right: map-get($inner-gutters, 'large');
      }
      @include breakpoint('xlarge') {
        margin-left: 0;
        margin-right: map-get($inner-gutters, 'xlarge');
      }
    }

    .slide__text {
      margin: 0;
      @include breakpoint('large+') {
        @include push(1, 'large');
      }
      @include breakpoint('xlarge') {
        @include push(1, 'xlarge');
      }
    }
  }
}
.page--slides {
  .slide--column {
    .slide__content {
      align-content: center;
      flex-direction: column;

      .slide__text {
        order: 2;
      }

      .slide__image {
        order: 1;
      //  width: inherit;
      //  margin-left: auto;
      //  margin-right: auto;

        @include breakpoint('large+') {
          margin-left: auto;
          margin-right: auto;
          max-width: 80vh;
        }
      }

      .slide__text {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.imageContent {
  position: relative;
}
