.modal.modal--outro {
  .section--final {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9;

    .inner {
      width: 100%;
      height: 100%;
    }
  }

  .modal__close {
    display: none;
  }
  .modal__inner {
    padding-top: 0;
    width: 100%;
  }
}

.section--dark {
  background: $color-navy;
  @extend %container;
}

.section--light {
  width: 100%;
  background: #f5f2ee;
  padding-bottom: 50px;
}

.hero__content {
  color: $color-white;

  h2 {
    @include header-05;
    @include breakpoint('medium-') {
      @include header-03;
    }
  }
  p {
    @include ui-08;
    font-family: 'Avenir';
    margin-top: 26px;
    &:first-child {
      margin-top: 0;
    }
    @include breakpoint('medium-') {
      font-size: 21px;
      line-height: 125%;
    }
  }

  .hero__audio {
    .audioPlayer {
      justify-content: end;
      margin-top: 10px;
      margin-bottom: 10px;

      .audioPlayer__trigger {
        color: $color-white;
      }
    }
  }
}

.hero__controls {
  margin-top: 32px;
  display: inline-flex;

  @include breakpoint('large-') {
    display: block;
    padding-bottom: 48px;
  }
  button {
    margin-right: 35px;
    @include breakpoint('large-') {
      margin: 0;
      margin-top: 12px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.hero__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .hero__inner {
    width: 50%;
    padding-top: 80px;
    @include breakpoint('medium-') {
      width: 100%;
    }
  }
}

.hero__image {
  overflow: hidden;
  width: 40%;
  @include breakpoint('medium-') {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }
}
