.timeline {
  margin-top: 48px;
  position: relative;
  padding-left: 15px;
  padding-bottom: 50px;
  padding-top: 10px;

  @include breakpoint('medium-') {
    padding-left: 15px;
  }

  &:before {
    top: 0;
    content: '';
    width: 1px;
    left: 18px;
    height: 100%;
    position: absolute;
    background: white;
  }
}

.timeline__content {
  position: relative;
  &:before {
    content: '';
    height: 3px;
    width: 3px;
    background: white;
    position: absolute;
    top: -40px;
    left: 2px;
  }
  &:after {
    content: '';
    height: 3px;
    width: 3px;
    background: white;
    position: absolute;
    bottom: -50px;
    left: 2px;
  }
}

.yearSection {
  position: relative;
}

.yearSection__year {
  @include ui-10;
  position: relative;
  padding-left: 38px;
  margin-top: 30px;

  svg {
    margin-left: 8px;
    // position: relative;
    // top: -4px;
  }

  button {
    @include ui-10;
    @include buttonReset();
    padding: 0;
    display: flex;
    align-items: center;
    color: $color-white;
  }
}

.yearSection__text {
  @include ui-04;
}

.yearSection__excerpt,
.yearSection__text {
  padding-left: 38px;
}

.yearSection__text {
  margin-top: 10px;
}

.dateSection {
  margin-top: 30px;

  .dateSection__heading {
    @include ui-11;

    button {
      @include ui-11;
      @include buttonReset();
      padding: 0;
      display: flex;
      align-items: center;
      color: $color-white;
    }
  }
}

.dateSection__excerpt,
.yearSection__excerpt {
  @include ui-04;
  svg {
    margin-left: 5px;
  }
}

.dateSection {
  position: relative;
  padding-left: 38px;
}

.dateSection__pointer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-navy;
  height: 28px;
  width: 28px;
  left: -10px;
  top: 8px;
  border: 1px solid white;
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 16px;
  position: absolute;
  text-align: center;
  span {
    position: relative;
    top: 2px;
  }
}

.pointer {
  left: 0px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-navy;
  height: 1px;
  width: 7px;
  top: 20px;
  border: 1px solid white;
  text-indent: -99999px;
}

.dateSection__text {
  margin-top: 10px;
  @include ui-04;
}

.dateSection--expander {
  h4 {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s opacity;
    svg {
      margin-left: 8px;
    }
    @include breakpoint('large+') {
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.yearSection--expander {
  h3 {
    outline: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s opacity;
    svg {
      margin-left: 8px;
    }
    @include breakpoint('large+') {
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.dateSection--expanded,
.yearSection--expanded {
  h4,
  h3 {
    svg {
      transform: rotate(180deg);
    }
  }
}
