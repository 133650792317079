.optionModal {
  .modal__content {
    border: none;
    padding-right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    @include breakpoint('medium-') {
      height: calc(100% - 60px);
      border: none;
    }
  }

  .modal__close {
    @include breakpoint('medium-') {
      right: 0;
    }
  }

  .closeText {
    @include breakpoint('medium-') {
      display: none;
    }
  }

  .closeIcon {
    @include breakpoint('medium-') {
      display: block;
    }
  }

  .selection {
    @include width(6, 'xlarge');
    @include breakpoint('large') {
      @include width(6, 'large');
    }
    @include breakpoint('medium') {
      @include width(8, 'medium');
    }
    @include breakpoint('small-') {
      width: 100%;
    }
  }

  .modal__inner {
    @include breakpoint('medium-') {
      height: 100%;
    }
  }
  .selection__title {
    @include header-05;
  }
  .selection__text {
    margin-top: 40px;
    p {
      margin-top: 29px;
      @include ui-08;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  .selection__audio {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .audioPlayer {
      margin-top: 0;
    }
    .audioPlayer__trigger {
      color: $color-white;
    }
  }

  .optionButtons {
    padding-top: 40px;
    padding-bottom: 25px;
    margin-top: auto;
    display: flex;

    justify-content: space-between;
    @include breakpoint('xsmall') {
      display: block;
      flex-direction: column;
      .button {
        margin-top: 10px;
      }
    }
  }
}
