.ReactModal__Content {
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  border: none !important;
  background: none !important;
  overflow: auto !important;
  border-radius: 4px !important;
  outline: none !important;
  padding: 0px !important;
}

.ReactModal__Overlay {
  z-index: 3;
  background: none !important;
}

.modal {
  opacity: 0;
  position: fixed;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  background: $color-navy;
  top: 0;
  left: 0;
  z-index: 99;
}

.modal__scroll {
  overflow: auto;
  height: 100%;
}

.modal__inner {
  @extend %container;
  position: relative;
  padding-top: 80px;
  // padding-bottom: 80px;
  min-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  @include breakpoint('medium-') {
    padding-top: 60px;
    flex-direction: column;
  }
}

.modal__content {
  color: $color-white;
  padding-top: 10px;
  padding-bottom: 50px;
  border-top: 2px solid $color-white;
  @include width(6, 'xlarge');
  @include breakpoint('large') {
    @include width(6, 'large');
  }
  @include breakpoint('medium-') {
    width: 100%;
    padding-top: 0;
    padding-right: 0;
    margin-top: 0px;

    padding-top: 15px;
  }
  h2 {
    @include header-03;
  }
  .modal__text {
    margin-top: 32px;
    p:first-child {
      margin-top: 0;
    }
  }
  p {
    margin-top: 24px;
    @include ui-03;
  }
}

.modal__media {
  position: relative;
  @include width(6, 'xlarge');
  margin-left: map-get($inner-gutters, 'xlarge');

  @include breakpoint('large') {
    @include width(6, 'large');
    margin-left: map-get($inner-gutters, 'large');
  }
  @include breakpoint('medium-') {
    width: 100%;
    margin: 0;
    margin-top: 32px;
  }
}

.modal__text {
  .tooltip {
    background: $color-offwhite;
    color: $color-text;
    .button {
      border: 1px solid black;
    }
  }

  .wordDefine {
    color: white;
    background-image: linear-gradient(
      to bottom,
      $color-white 75%,
      $color-white 75%
    );
    white-space: nowrap;
  }
}

.detailPage__more {
  margin-top: 48px;
  p {
    color: $color-white;
    @include ui-02;
  }

  .button {
    display: inline-flex;
    margin-top: 20px;
  }
}

.modal__close {
  @include buttonReset;
  position: absolute;
  right: 0;
  top: 27px;
  .closeText {
    display: none;
  }

  @include breakpoint('large+') {
    svg {
      transition: all 0.3s;
    }

    &:hover {
      path {
        fill: #e5e5e5;
      }
    }
  }

  @include breakpoint('medium-') {
    top: 20px;
    right: auto;

    .closeIcon {
      display: none;
    }

    .closeText {
      display: flex;
      align-items: center;
      @include link;
      color: $color-white;
      svg {
        margin-right: 8px;
      }
    }
  }
}

//specific modals

.mediaModal {
  z-index: 99999 !important;
}
.timelineModal,
.outro {
  z-index: 99999 !important;
}

.zoomModal {
  z-index: 99999 !important;
  .modal {
    height: 100% !important;
  }
}

.zoomModal {
  .modal {
    height: 100vh;
  }
  .modal__inner {
    padding: 0;
  }
  .modal__close {
    display: none;
  }

  .modal__inner {
    width: 100%;
  }
}
