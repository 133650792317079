.infoBubble {
  padding: 0;
  color: $color-white;
  position: relative;
  border-radius: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  background: $color-blue;
  height: 50px;
  width: 50px;
  transition: all 0.4s;
  font-family: 'Avenir';
  font-size: 30px;
  font-weight: 700;
  position: relative;

  span {
    position: relative;
    top: 2px;
  }

  &:hover {
    color: black;
    background: $color-orange;
  }
}

.infoBubble--active {
  color: black !important;
  background: $color-orange !important;
  &:hover {
    color: black;
    background: $color-orange;
  }
}
