.slideControls {
  position: fixed;
  left: 0;
  bottom: 26px;
  width: 100%;

  z-index: 3;

  @include breakpoint('medium-') {
    display: none;
  }

  .inner {
    @extend %container;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .slideArrow {
    transition: all 0.3s;
    margin-left: 2px;
  }

  .slideArrow:not(.slideArrow--disabled) {
    &:hover {
      @include hover-supported() {
        background: $color-blue;
        path {
          fill: $color-white;
        }
      }
    }
  }

  .slideArrow--prev {
    background: #fff;
    border: 1px solid $color-blue;
  }
  .slideArrow--next {
    svg {
      position: relative;
      top: 1px;
    }
  }

  .slideHeader__arrows {
    display: flex;

    button {
      width: 52px;
      height: 52px;
    }
  }
}

.slideControls__count {
  @include link;
  text-align: center;
}
