$option: (
  xsmall: 11,
  small: 11,
  medium: 11,
  large: 4,
  xlarge: 4,
);

.slide--options {
  @include breakpoint('medium-') {
    .slide__image {
      display: none;
    }
  }

  .slide__content {
    .slide__text {
      h2 {
        @include header-04;
        color: $color-red;
        font-weight: 700;
        @include width(8, 'xlarge');
        @include breakpoint('large') {
          @include width(8, 'large');
        }
    
        @include breakpoint('medium-') {
          padding-top: 24px;
        }
      }
    
      p {
        @include ui-08;
      }
    
      .button {
        margin-top: 26px;
      }
    
      .slide__audio {
        @include breakpoint('medium-') {
            // 
        }
      }
    }
  }
}

.options {
  margin-top: 16px;
}

.option {
  background-color: #eae6df;
  padding: 56px 40px;

  &:not(:first-child) {
    margin-top: 16px;
  }

  button svg path {
    fill: $color-white;
  }

  @include column-flex($option);
  @include breakpoint('medium-') {
    padding: 40px 30px;
  }

  margin-left: 0 !important;
}
