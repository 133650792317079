.media {
  overflow: hidden;
  background: black;
  img {
    display: block;
    width: 100%;
  }
}

.media__attr {
  color: $color-white;
  margin-top: 8px;
  @include ui-02;
}

.imageComponent {
  position: relative;
  .iconButton {
    top: 12px;
    right: 12px;
    position: absolute;
  }
}
