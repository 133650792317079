.detailPage {
  color: $color-white;
  padding-top: 10px;
  padding-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @include breakpoint('medium-') {
    padding-top: 0;
    border-top: 2px solid $color-white;
  }

  h2 {
    @include header-03;
  }
  .modal__text {
    margin-top: 32px;
    p:first-child {
      margin-top: 0;
    }
  }
}

.detailPage__col {
  @include width(6, 'xlarge');
  @include breakpoint('large') {
    @include width(6, 'large');
  }
  &:nth-child(2n) {
    margin-left: map-get($inner-gutters, 'xlarge');
    @include breakpoint('large') {
      margin-left: map-get($inner-gutters, 'large');
    }
  }

  @include breakpoint('medium-') {
    width: 100%;
    &:nth-child(2n) {
      margin-left: 0;
      @include breakpoint('large') {
        margin-left: 0;
      }
    }
  }
}

.detailPage__col--main {
  border-top: 2px solid $color-white;
  padding-top: 16px;
  @include breakpoint('medium-') {
    border-top: none;
  }
}

.detailPage__text {
  @include ui-03;
  margin-top: 32px;
  h3 {
    @include header-07;
    display: block;
    font-weight: 700;
    margin-top: 24px;
  }
  h4 {
    margin-top: 24px;
  }
  p {
    margin-top: 24px;
    @include ui-03;
  }
  a {
    color: $color-white;
    &:hover {
      opacity: 0.8;
    }
  }
  .tooltip {
    background: $color-offwhite;
    color: $color-text;
    .button {
      border: 1px solid black;
    }
  }
  .wordDefine {
    color: white;
    background-image: linear-gradient(
      to bottom,
      $color-white 75%,
      $color-white 75%
    );
    white-space: nowrap;
  }

  ul {
    list-style: none;
  }

  ol {
    margin-top: 25px;
    counter-reset: item;
  }
  ol li {
    display: block;
    margin-top: 10px;
  }
  ol li:before {
    content: counter(item) '.';
    counter-increment: item;
    padding-right: 10px;
    color: white;
  }

  ul {
    margin-top: 25px;
    & > li {
      margin-top: 10px;
      padding-left: 18px;
      position: relative;
      // color: green;
      &:before {
        content: '\25CF';
        position: absolute;
        color: $color-white;
        font-size: 9px;
        left: 0;
        top: 5px;
      }
    }
  }
}

.detailPage__media {
  position: relative;

  @include breakpoint('medium-') {
    width: 100%;
    margin: 0;
    margin-top: 32px;
  }
}

.modal__more {
  margin-top: 48px;
  p {
    color: $color-white;
    @include ui-04;
  }

  .button {
    display: inline-flex;
    margin-top: 20px;
  }
}

.detailPage__audio {
  margin-top: 32px;
  width: 100%;

  .audioPlayer{
    justify-content: end;
  }

  .audioPlayer__trigger {
    color: $color-white;
  }
}

.detailPage__quote {
  margin-top: 32px;
}

.detailTags {
  .tag {
    border: 1px solid $color-white;
    color: $color-white;
    padding: 4px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.detailPage__details {
  margin-top: 20px;
  display: flex;
  align-items: center;

  .infoButton {
    margin-left: 12px;
  }
}

.detail__media {
  @include breakpoint('medium-') {
    margin-top: 32px;
  }
}

/////////////////////////////////
//
// sections
//
/////////////////////////////////

.tabNavImage {
  position: relative;
  .hotspotTab,
  .infoBubble {
    position: absolute;
  }
  img {
    width: 100%;
  }
}

.tooltip--light {
  background: $color-offwhite;
  color: $color-text;
  .button {
    border: 1px solid black;
  }
}
