.personSelect_header {
  margin-left: 56px;
  @include width(4, 'xlarge');
  @include breakpoint('large') {
    @include width(4, 'large');
  }

  h1 {
    @include header-06;
    text-transform: uppercase;
    color: $color-red;
  }
  h2 {
    @include header-02;
    color: black;
    margin-top: 12px;
  }
  .button {
    margin-top: 32px;
  }
  align-self: center;
}

.slider {
  overflow: hidden;
}

.personSelector {
  position: absolute;

  right: 27px;
  top: 50%;
  transform: translateY(-50%);

  img {
    display: block;
  }

  .arrow--next,
  .arrow--prev {
    path {
      fill: white;
    }
    @include buttonReset;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* */
    display: block;
    margin: 0 auto;
    width: 52px;
    height: 52px;
    background: rgba(black, 0.7);
    &:hover,
    &:focus {
      background: rgba(black, 1);
    }
    &:disabled {
      pointer-events: none;
      cursor: default;
      opacity: 0.5;
    }

    &.arrow--prev {
      border-radius: 2px;
      transform: rotate(90deg);
      margin-bottom: 18px;
    }
    &.arrow--next {
      border-radius: 2px;
      position: relative;
      margin-top: 2px;
      transform: rotate(90deg);
      margin-top: 18px;
    }
  }
}

.carousel-cell {
  @include buttonReset;
  padding: 0;
  display: block;
  cursor: pointer;
  position: relative;
  &:before {
    content: '';
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #ccc;
  }

  width: 120px;
  margin-top: 2px;
  opacity: 0.8;
  transition: 0.4s opacity;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* */
  &:hover,
  &:focus {
    opacity: 1;
  }
  img {
    width: 100%;
  }
}

.carousel-cell--selected {
  position: relative;
  &:before {
    content: '';
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 3px solid #004899;
  }
  opacity: 1;
}

.page--select {
  @include breakpoint('medium-') {
    overflow: auto;
    margin: 0;
    width: 100%;
    height: 100%;

    // .panel {
    //   // height: 100%;
    //   border: none;
    // }
    .panel__inner {
      display: block;
    }
    .largePerson {
      display: none;
    }
    .personSelect_header {
      display: none;
    }
    .personSelector {
      display: none;
    }
  }
}

.mobileSelect {
  display: none;
  @include breakpoint('medium-') {
    display: block;
  }
}

.mobileSelect__person {
  background: white;
  display: flex;
  border-bottom: 1px solid #cccccc;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;

  img {
    width: 158px;
  }
  h3 {
    @include width(6);
    @include link;
    color: black;
    display: flex;
    align-items: center;
    svg {
      margin-left: 8px;
    }
  }
  &:nth-child(odd) {
    img {
      margin-left: auto;
    }
  }
  &:nth-child(even) {
    h3 {
      order: 2;
      margin-left: 46px;
    }
    img {
      order: 1;
    }
  }
}

.mobileHeader {
  display: none;
  background: #f1f3f2;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  height: 60px;
  border-bottom: 2px solid #cccccc;
  @include breakpoint('medium-') {
    display: block;
  }

  h1 {
    @include header-06;
    font-weight: 700;
    text-transform: uppercase;
    color: $color-red;
  }
}

.mobileHeader__inner {
  @extend %container;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.largePerson {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
}

.largePerson__image {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  object-position: top;
  position: absolute;
  opacity: 0;
}
